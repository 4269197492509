import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import CloseIcon from "@mui/icons-material/Close";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import { Button, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import {
  ActivateUser,
  VerifyUser,
  deleteUser,
} from "../../../Services/adminServices";
import configData from "../../../config/production.json";
import ConfirmationDialog from "../../../Models/ConfirmationDialog";

const { SERVER_URL } = configData;

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  [`&.${tableCellClasses.head}`]: {
    color: "#4F4F4F",
    backgroundColor: "#ffeecc",
    height: "17px",
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.08em",
    textTransform: "uppercase",
  },
}));

const root = {
  width: "97%",
};

const icons = {
  display: "flex",
  justifyContent: "space-between",
};
const avatar = {
  borderRadius: "50%",
  width: "30px",
  height: "30px",
};

export default function CaregiversTable({
  setCaregiversToViewed,
  setViewCaregiversModal,
  setEditCaregiversModal,
  caregivers,
  setUpdateTable,
  updateTable,
}) {
  const { t } = useTranslation();
  const [loader, setLoader] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const handleApprove = (id, activation) => {
    setLoader(true);
    ActivateUser(id, !activation).then((response) => {
      if (response.status === 200) {
        setUpdateTable(!updateTable);
      }
      setLoader(false);
    });
  };
  const handleView = (id) => {
    setCaregiversToViewed(id);
    setViewCaregiversModal(true);
  };
  const handleEdit = (id) => {
    setCaregiversToViewed(id);
    setEditCaregiversModal(true);
  };
  const columns = [
    { id: "user_id", label: t("id"), minWidth: 16 },
    {
      id: "full_name",
      label: t("name"),
      minWidth: 46,
    },
    {
      id: "mobile",
      label: t("mobile"),
      minWidth: 64,
    },
    {
      id: "email",
      label: t("email"),
      minWidth: 64,
    },
    {
      id: "student_count",
      label: t("childrenCount"),
      minWidth: 20,
    },
    {
      id: "total_applications_count",
      label: t("total_applications_count"),
      minWidth: 20,
    },
    {
      id: "verify_at",
      label: t("verifyAt"),
    },
  ];

  useEffect(() => {
    setPage(0);
  }, [caregivers]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleLoad = () => {};

  const handleVerify = (id) => {
    setLoader(true);
    VerifyUser(id)
      .then((response) => {
        if (response.status === 200) {
          setUpdateTable(!updateTable);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleDelete = (id) => {
    // Show the confirmation dialog
    setShowConfirmation(true);

    // Set the ID of the item to be deleted
    setDeleteItemId(id);
  };

  const handleConfirmDelete = () => {
    setLoader(true);

    // Perform the deletion using deleteSP
    deleteUser(deleteItemId).then((response) => {
      if (response.status === 200) {
        setUpdateTable(!updateTable);
      }
      setLoader(false);
    });

    // Hide the confirmation dialog after deletion
    setShowConfirmation(false);
  };

  const handleCancelDelete = () => {
    // Hide the confirmation dialog without performing the deletion
    setShowConfirmation(false);
    setDeleteItemId(null); // Reset the item ID
  };

  return (
    <Paper sx={root}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: column.backgroundColor,
                    border: column.border,
                  }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell align="center">{t("photo")}</StyledTableCell>
              <StyledTableCell align="center">{t("operation")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {caregivers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <StyledTableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number" ? (
                          column.format(value)
                        ) : column.id === "verify_at" ? (
                          row?.verify_at ? (
                            row?.verify_at
                          ) : (
                            <Button
                              variant="contained"
                              onClick={() => {
                                handleVerify(row?.user_id);
                              }}
                            >
                              {t("verify")}
                            </Button>
                          )
                        ) : column.id === "total_applications_count" ? (
                          `${row?.total_applications_count} / ${row?.applications_with_status_count}`
                        ) : (
                          value
                        )}
                      </StyledTableCell>
                    );
                  })}
                  {columns
                    .filter((column) => column.id === "user_id")
                    .map((column) => (
                      <StyledTableCell key={column.id} align="center">
                        <img
                          src={
                            row.photo
                              ? `${SERVER_URL}/storage/${row.photo}`
                              : "/logo/default.png"
                          }
                          width={30}
                          height={30}
                          alt="avatar"
                          style={avatar}
                        />
                      </StyledTableCell>
                    ))}
                  {columns
                    .filter((column) => column.id === "user_id")
                    .map((column) => (
                      <StyledTableCell key={column.id}>
                        {row.is_active ? (
                          <IconButton
                            aria-label="visibility"
                            onClick={() => {
                              handleApprove(row.user_id, row.is_active);
                            }}
                            disabled={loader}
                          >
                            <CheckIcon
                              style={{ color: loader ? "gray" : "green" }}
                            />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label="close"
                            onClick={() => {
                              handleApprove(row.user_id, row.is_active);
                            }}
                            disabled={loader}
                          >
                            <CloseIcon
                              style={{ color: loader ? "gray" : "red" }}
                            />
                          </IconButton>
                        )}
                        <IconButton
                          aria-label="visibility"
                          onClick={() => {
                            handleView(row.user_id);
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            handleEdit(row.user_id);
                          }}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            handleDelete(row.user_id);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </StyledTableCell>
                    ))}
                </TableRow>
              ))}
          </TableBody>
          {showConfirmation && (
            <ConfirmationDialog
              confirmCallback={handleConfirmDelete}
              cancelCallback={handleCancelDelete}
            />
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={caregivers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
CaregiversTable.propTypes = {
  setViewCaregiversModal: PropTypes.func.isRequired,
  setCaregiversToViewed: PropTypes.func.isRequired,
  caregivers: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      mobile: PropTypes.string.isRequired,
      gender: PropTypes.string.isRequired,
      photo: PropTypes.string.isRequired,
      user_id: PropTypes.number.isRequired,
      is_active: PropTypes.number.isRequired,
    })
  ).isRequired,
  setUpdateTable: PropTypes.func.isRequired,
  updateTable: PropTypes.bool.isRequired,
};
